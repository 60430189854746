<template>
  <div id="print" style="color:black;">
    <!-- 标题 -->
    <div>
      <p class="title">上海奥吉实业有限公司销售出库单</p>
      <div style="float: right;">
        <img src="~@/assets/OGlogo.png" alt="" width="100px" height="70px;">
      </div>
      <p>
        <span>公司地址：中国上海自由贸易试验区奥纳路18号</span><br>
        <span>联系人：张洁   电话：021-58666868*872</span><br>
        <span>开户行及账号：招商银行上海分行长阳支行支行214081698410002</span><br>
      </p>
    </div>

    <!-- 信息 -->
    <div style="margin-bottom: 10px;">
      <a-row>
        <a-col :span="8"></a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          出库号：{{ orderData.sell_num }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          购买单位：{{ orderData.buyer_name }}
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          出库日期：{{ orderData.out_date }}
        </a-col>
      </a-row>
    </div>

    <!-- 表格 -->
    <div v-for="(item, index) in orderList" :key="index">
      <table style="border-color: #000;">
        <thead>
          <tr style="border-color: #000;">
            <th style="width: 500px;border-color: #000;">商品信息</th>
            <th style="width: 100px;border-color: #000;">数量</th>
            <th style="width: 100px;border-color: #000;">单价</th>
            <th style="width: 100px;border-color: #000;">金额</th>
            <th style="width: 100px;border-color: #000;">备注</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(good, goodIdx) in item.good_list" :key="goodIdx">
            <td style="border-color: #000;">{{ good.good_info }}</td>
            <td style="border-color: #000;">{{ good.num }}</td>
            <td style="border-color: #000;">{{ good.unit_price }}</td>
            <td style="border-color: #000;">{{ good.total_price }}</td>
            <td style="border-color: #000;">{{ good.remark }}</td>
          </tr>
        </tbody>
      </table>
      <a-divider orientation="right" v-if="item.address">
        <span style="font-weight: bold;">{{ item.address }}</span>
      </a-divider>
    </div>

    <!-- 代理费、合计行 -->
    <table>
      <tbody>
        <tr v-if="orderData['agencyFee'] && orderData['agencyFee'] > 0">
          <td style="width: 500px;border-color: #000;">代理费</td>
          <td style="width: 100px;border-color: #000;"></td>
          <td style="width: 100px;border-color: #000;"></td>
          <td style="width: 100px;border-color: #000;">{{ orderData['agencyFee'] }}</td>
          <td style="width: 100px;border-color: #000;"></td>
        </tr>
        <tr>
          <td style="width: 500px;font-weight: bold;border-color: #000;">合计</td>
          <td style="width: 100px;font-weight: bold;border-color: #000;">{{ orderData['totalNum'] }}</td>
          <td style="width: 100px;border-color: #000;"></td>
          <td style="width: 100px;font-weight: bold;border-color: #000;">{{ orderData['totalPrice'] }}</td>
          <td style="width: 100px;border-color: #000;"></td>
        </tr>
      </tbody>
    </table>

    <!-- 底部 -->
    <p style="margin-top: 10px;">
      <a-row :gutter="24">
        <a-col :span="8"><span class="bottomStyle">发货人：</span></a-col>
        <a-col :span="8"><span class="bottomStyle">审批人：</span>陈瑞雯</a-col>
        <a-col :span="8"><span class="bottomStyle">订购人：</span>{{ orderData.order_person }}</a-col>
      </a-row>
      <a-row :gutter="24" style="margin-top: 10px;">
        <a-col :span="8"><span class="bottomStyle">最晚送货时间：</span>{{ orderData.latest_delivery_time }}</a-col>
        <a-col :span="8"><span class="bottomStyle">备注：</span>{{ orderData.remark }}</a-col>
        <a-col :span="8">
          <span class="bottomStyle">交易时间：</span>
          <span v-for="(obj, index) in orderData.cash_record_list" :key="index">
            {{ obj.pay_date }}
          </span>
        </a-col>
      </a-row>
      <a-row :gutter="24" style="margin-top: 10px;">
        <a-col :span="8">
          <span class="bottomStyle">交易号：</span>
          <span v-for="(obj, index) in orderData.cash_record_list" :key="index">
            {{ obj.trx_id }}
          </span>
        </a-col>
      </a-row>
    </p>
  </div>
</template>

<script>
export default {
  name: `SalePurchaseSheetBill`,
  props: {
    currentData: { type: Object, default: null },
    balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val;
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: []
    }
  },
  methods: {
    initData() {
      // 初始化数据
      this.orderData = this.currentData;
      this.orderList = this.balanceData;
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 10px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
.title { text-align: center; font-weight: bold; font-size: 16px; }
.bottomStyle { font-weight: bold; }
</style>
